/* eslint-disable react-hooks/exhaustive-deps */
import { Badge } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import ReactDatetimeClass from "react-datetime";
import { useForm } from "react-hook-form";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Row,
} from "reactstrap";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import readXlsxFile from "read-excel-file";
import { useNavigate } from "react-router-dom";
import useToast from "../../../../hooks/useToast";
import { uploadDocument } from "../../../../hooks/upload-documents.hook";
import Spinner from "../../../../components/Spinner/Spinner";
import { useMutation } from "@apollo/client";
import {
  GET_AGEING_REPORT_LIST,
  INSERT_AGEING_REPORT,
} from "../../../../graphql/financial-report.graphql";

type FormData = {
  title: string;
  date: string;
};

interface AgeingReportItemInput {
  unit_number: string;
  tenant_mm_colab: string;
  one_twenty_plus_days: number;
  ninety_days: number;
  sixty_days: number;
  thirty_days: number;
  current: number;
  total_due: number;
  tenant_name: string;
}

export default function AgeingReportImportForm() {
  const fileInputRef = useRef<HTMLInputElement>();
  const [file, setSelectedFile] = useState<File>();
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>();

  const navigate = useNavigate();
  const { showToast, Notification } = useToast();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [reportInput, setReportInput] = useState<AgeingReportItemInput[]>([]);
  const [insertAgeingReport, { data, error }] = useMutation(
    INSERT_AGEING_REPORT,
    {
      refetchQueries: [{ query: GET_AGEING_REPORT_LIST }],
    }
  );

  useEffect(() => {
    if (error) {
      showToast(
        "An error has occurred while trying to add the report",
        "danger"
      );

      setIsLoading(false);
    }
  }, [error]);

  useEffect(() => {
    if (data) {
      showToast("Successfully added the report", "success");
      navigate(`/admin/finance/manage/${data?.report.id}`);
      setIsLoading(false);
    }
  }, [data]);
  const handleFileAttach = async (e: any) => {
    setSelectedFile(e.target.files[0]);

    try {
      const excelFile = await readXlsxFile(e.target.files[0]);
      const newAgeingInputList: AgeingReportItemInput[] = [];
      excelFile.forEach((row, index) => {
        if (index > 1) {
          const input = {
            unit_number: row[0]?.toString() as string | undefined,
            tenant_mm_colab: row[1]?.toString() as string | undefined,
            tenant_name: row[2]?.toString() as string | undefined,
            one_twenty_plus_days: row[3] as number | undefined,
            ninety_days: row[4] as number | undefined,
            sixty_days: row[5] as number | undefined,
            thirty_days: row[6] as number | undefined,
            current: row[7] as number | undefined,
            total_due: row[8] as number | undefined,
          };

          newAgeingInputList.push(input);
        }
      });

      setReportInput(newAgeingInputList);
    } catch (error) {
      console.log("Error reading xlsx file", error);
    }
  };

  const openFileExplorer = () => {
    if (fileInputRef.current) fileInputRef?.current?.click();
  };

  const onSubmit = async (formData: FormData) => {
    if (!file) {
      showToast("Please make sure that a file is selected", "danger");
      return;
    }

    let documentKey: string = "";

    setIsLoading(true);
    documentKey = await uploadDocument(file, "ageing_reports");

    if (documentKey === "error") {
      showToast(
        "An error has occurred while trying to upload this file",
        "danger"
      );
      setIsLoading(false);
      return;
    }

    const report = {
      name: formData.title,
      date: formData.date,
      file_path: documentKey,
      items: {
        data: reportInput,
      },
    };

    insertAgeingReport({
      variables: {
        report: report,
      },
    });
  };

  return (
    <>
      {Notification}
      <Container className="mt-4" fluid>
        <Row>
          <Col md="12" lg="6">
            <Card>
              <CardHeader>
                <Row noGutters={true}>
                  <button
                    className="btn btn-outline-primary btn-sm mr-4"
                    onClick={() => navigate(-1)}
                  >
                    <i
                      className="fas fa-angle-left"
                      style={{ fontSize: "14px" }}
                    />
                    <span className="btn-inner-text">Back</span>
                  </button>
                  <h3 className="m-0">Import Ageing Report</h3>
                </Row>
              </CardHeader>
              <CardBody>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="row">
                    <div className="col-md-12">
                      <FormGroup>
                        <label className="form-control-label" htmlFor="subject">
                          Title
                        </label>
                        <input
                          className="form-control"
                          {...register("title", { required: true })}
                          id="subject"
                          placeholder="Enter ageing report title..."
                          type="text"
                        />
                      </FormGroup>
                      {errors.title && (
                        <span className="invalid">*This field is required</span>
                      )}
                    </div>
                    <div className="col-md-12">
                      <FormGroup>
                        <label className="form-control-label" htmlFor="name">
                          Date
                        </label>
                        <ReactDatetimeClass
                          inputProps={{
                            placeholder: "Enter starting date...",
                            autoComplete: "off",
                            ...register("date", { required: true }),
                          }}
                          dateFormat={"YYYY-MM-DD"}
                          onChange={(date: moment.Moment) => {
                            setValue("date", date.format("YYYY-MM-DD"));
                          }}
                          timeFormat={false}
                        />
                      </FormGroup>
                      {errors.date && (
                        <span className="invalid">*This field is required</span>
                      )}
                    </div>

                    <div className="col-md-12">
                      <FormGroup>
                        <span
                          className=" mt-4 btn btn-round btn-dark btn-sm"
                          color="default"
                          onClick={openFileExplorer}
                          id="tooltip969372949"
                        >
                          <span className="btn-inner--text"> Attach file</span>
                        </span>
                        <input
                          onChange={handleFileAttach}
                          style={{ display: "none" }}
                          multiple
                          accept=".pdf, .docx, .xlsx"
                          type="file"
                          ref={fileInputRef}
                        />
                      </FormGroup>

                      {file && (
                        <>
                          <Badge
                            style={{ cursor: "pointer" }}
                            id={file.name}
                            badgeContent={"X"}
                            // onClick={() => onFileRemove(file)}
                            key={file.name}
                            color="primary"
                          >
                            <InsertDriveFileOutlinedIcon fontSize={"large"} />
                          </Badge>
                          <p>{file.name.substring(0, 5)}</p>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="d-flex h-100">
                    <div className="align-self-end ml-auto">
                      <Button
                        disabled={isLoading}
                        type="submit"
                        className="btn btn-dark mt-4 btn-block"
                      >
                        {!isLoading && <span>{"Import"}</span>}
                        {isLoading && (
                          <span>
                            <Spinner />
                          </span>
                        )}
                      </Button>
                    </div>
                  </div>
                </form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}
