import { gql } from '@apollo/client';

export const GET_AGEING_REPORT_LIST = gql`
    query GetAgeingReportList {
        reports: ageing_report {
            id
            date
            filePath: file_path
            name
        }
    }
`;

export const INSERT_AGEING_REPORT = gql`
    mutation AddAgeingReport($report: ageing_report_insert_input!) {
        account: update_user_account(where: { status_enum: { _eq: in_arrears } }, _set: { status_enum: active }) {
            returning {
                id
            }
        }
        report: insert_ageing_report_one(object: $report) {
            id
        }
    }
`;

export const UPDATE_AGEING_REPORT = gql`
    mutation UpdateAgeingReport($id: uuid!, $report: ageing_report_set_input!) {
        report: update_ageing_report_by_pk(pk_columns: { id: $id }, _set: $report) {
            id
        }
    }
`;

export const GET_AGEING_REPORT = gql`
    query GetAgeingReport($id: uuid!) {
        report: ageing_report_by_pk(id: $id) {
            id
            name
            date
        }
    }
`;

export const GET_AGEING_REPORT_ITEMS = gql`
    query GetAgeingReportItems($reportId: uuid!) {
        reportItems: ageing_report_item(where: { ageing_report_id: { _eq: $reportId } }) {
            id
            unitNumber: unit_number
            tenantMMColab: tenant_mm_colab
            tenantName: tenant_name
            oneTwentyPlusDays: one_twenty_plus_days
            ninetyDays: ninety_days
            sixtyDays: sixty_days
            thirtyDays: thirty_days
            current
            totalDue: total_due
        }
    }
`;
