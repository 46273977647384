import { Box, Tab, Tabs, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import AgeingReportDetailsForm from './AgeingReportDetailsForm';
import AgeingReportItemLitsView from './AgeingReportItemLitsView';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role='tabpanel'
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}>
            {value === index && (
                <Box sx={{ p: 0 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function AgeingReportTabView() {
    const [value, setValue] = React.useState(0);

    const navigate = useNavigate();
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };
    return (
        <Container className='mt-4' fluid>
            <Row className='mx-2'>
                <Col>
                    <Row className='pb-4'>
                        <button
                            className='btn btn-outline-primary btn-sm mr-4'
                            onClick={() => {
                                navigate(-1);
                            }}>
                            <i className='fas fa-angle-left' style={{ fontSize: '14px' }} />
                            <span className='btn-inner-text'>Back</span>
                        </button>
                        <h3 className='m-0'>Manage Ageing Report</h3>
                    </Row>
                    <Row>
                        <Box sx={{ width: '100%' }}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <Tabs value={value} onChange={handleChange} aria-label='basic tabs example'>
                                    <Tab sx={{ textTransform: 'capitalize' }} label='Details' {...a11yProps(0)} />
                                    <Tab sx={{ textTransform: 'capitalize' }} label='Report' {...a11yProps(1)} />
                                </Tabs>
                            </Box>
                            <Box>
                                <TabPanel value={value} index={0}>
                                    <AgeingReportDetailsForm />
                                </TabPanel>
                                <TabPanel value={value} index={1}>
                                    <AgeingReportItemLitsView />
                                </TabPanel>
                            </Box>
                        </Box>
                    </Row>
                </Col>
            </Row>
        </Container>
    );
}
