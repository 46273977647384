/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from 'react';
import { Button, Card, CardBody, FormGroup, Label } from 'reactstrap';
import { getPermission } from '../../../../utils/user.utils';
import { User } from '../../../../models/user.model';
import { useSelector } from 'react-redux';
import { Client } from '../../../../models/client.model';
import { GET_CLIENT, GET_CLIENTS, UPDATE_ACCOUNT_SETTINGS } from '../../../../graphql/clients.graphql';
import { useMutation } from '@apollo/client';
import Spinner from '../../../../components/Spinner/Spinner';
import useToast from '../../../../hooks/useToast';

interface ClientSettingsCardProps {
    client: Client;
}

const ClientSettingsCard = ({ client }: ClientSettingsCardProps) => {
    const [updateClientDetailsMutation, { loading: isUpdating, error }] = useMutation(UPDATE_ACCOUNT_SETTINGS, {
        refetchQueries: [{ query: GET_CLIENTS }, 'GetClients', GET_CLIENT, 'GetClient'],
    });

    const [includeCalendarInvites, setIncludeCalendars] = useState<boolean>(client.includeCalendarInvites || false);
    const user: User | null = useSelector((state: any) => state.user.userInfo);
    const [isColabMember, setIsColabMember] = useState<boolean>(client?.isColabMember || false);
    const [isPrecinctTenant, setIsPrecinctTenant] = useState<boolean>(client.isPrecinctTenant || false);

    const [allowAddToAccount, setAllowAddToAccount] = useState<boolean>(client.allowAddToAccount || false);

    const { showToast, Notification } = useToast();

    useEffect(() => {
        if (error) {
            console.log('Oops, an error has occurred', error);
            showToast('An error has occurred while trying to update the account settings', 'danger');
        }
    }, [error]);

    const updateAccountSettings = async () => {
        const accountSettings = {
            is_precinct_tenant: isPrecinctTenant,
            include_calendar_invites: includeCalendarInvites,
            is_colab_member: isColabMember,
            allow_add_to_account: allowAddToAccount,
        };

        await updateClientDetailsMutation({
            variables: {
                id: client.id,
                account: accountSettings,
            },
        });

        showToast('Successfully updated account settings.', 'success');
    };

    return (
        <>
            {Notification}
            <Card>
                <CardBody>
                    <h3>Client Account Settings</h3>
                    <form
                        onSubmit={(e) => {
                            e.preventDefault();
                            updateAccountSettings();
                        }}>
                        <h4>Member settings</h4>
                        <div className='col-lg-6 col-md-12 mt-4 pb-4'>
                            <FormGroup check inline>
                                <input
                                    type='checkbox'
                                    checked={isColabMember}
                                    className='mr-2'
                                    onChange={(e) => {
                                        if (isPrecinctTenant) {
                                            setIsPrecinctTenant(false);
                                        }

                                        setIsColabMember(e.target.checked);
                                    }}
                                />
                                <Label check>Is Colab Member?</Label>
                            </FormGroup>
                        </div>
                        <div className='col-lg-6 col-md-12 mt-4 pb-4'>
                            <FormGroup check inline>
                                <input
                                    type='checkbox'
                                    checked={allowAddToAccount}
                                    className='mr-2'
                                    onChange={(e) => {
                                        setAllowAddToAccount(e.target.checked);
                                    }}
                                />
                                <Label check>Allow Add to Account</Label>
                            </FormGroup>
                        </div>
                        <div className='col-lg-6 col-md-12 mt-4 mb-4'>
                            <FormGroup check inline>
                                <input
                                    type='checkbox'
                                    checked={isPrecinctTenant}
                                    className='mr-2'
                                    onChange={(e) => {
                                        if (isColabMember) {
                                            setIsColabMember(false);
                                        }
                                        setIsPrecinctTenant(e.target.checked);
                                    }}
                                />
                                <Label check> Is Precinct Tenant?</Label>
                            </FormGroup>
                        </div>
                        <h4>Configurations</h4>
                        <div className='col-lg-6 col-md-12 mt-4 pb-4'>
                            <FormGroup check inline>
                                <input
                                    type='checkbox'
                                    checked={includeCalendarInvites}
                                    className='mr-2'
                                    onChange={(e) => setIncludeCalendars(e.target.checked)}
                                />
                                <Label check>Include Calendar Invite</Label>
                            </FormGroup>
                        </div>

                        {getPermission(user, 'clients', 'update') && (
                            <div className='d-flex h-100'>
                                <div className='align-self-end ml-auto'>
                                    <Button type='submit' className='btn btn-dark mt-4'>
                                        {isUpdating && (
                                            <span>
                                                <Spinner />
                                            </span>
                                        )}
                                        {!isUpdating && <span>Update</span>}
                                    </Button>
                                </div>
                            </div>
                        )}
                    </form>
                </CardBody>
            </Card>
        </>
    );
};

export default ClientSettingsCard;
