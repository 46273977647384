/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
    Button,
    Card,
    CardFooter,
    CardHeader,
    Col,
    Container,
    Input,
    Pagination,
    PaginationItem,
    PaginationLink,
    Row,
    Table,
    UncontrolledTooltip,
} from 'reactstrap';
import BasicMenu from '../../../components/Menu';
import useToast from '../../../hooks/useToast';
import { User } from '../../../models/user.model';
import { getPermission } from '../../../utils/user.utils';
import { useQuery } from '@apollo/client';
import {
    GENERATE_PROFORMA_INVOICE,
    GENERATE_QUOTE_PDF,
    GET_QUOTES,
    GET_QUOTE_FORM_INFO,
} from '../../../graphql/quotes.graphql';
import { LinearProgress } from '@mui/material';
import { Quote, QuoteStatus, QuoteType } from '../../../models/quotes.models';
import _ from 'lodash';
import moment from 'moment';
import { Link, useNavigate } from 'react-router-dom';
import { REMOVE_QUOTE } from '../../../graphql/quotes.graphql';
import { useMutation } from '@apollo/client';
import SweetAlert from 'react-bootstrap-sweetalert';
import AddQuoteDialog from './dialogs/AddQuoteDialog';
import { Client } from '../../../models/client.model';
import LoaderDialog from './dialogs/LoaderDialog';
import { useLocalStorage } from '../../../hooks/local-storage.hook';

interface QuoteRouteData {
    keyWord: string;
    page: number;
    pageSize: number;
}

const QuoteListView = () => {
    const [alert, setAlert] = useState<any>(null);
    const [page, setPage] = useState<number>(0);
    const [getLocalStorage, setLocalStorage] = useLocalStorage('quote');
    const [orderFilter, setOrderFilter] = useState<string>('');
    const [rowPerPage, setRowsPerPage] = useState<number>(10);
    const [sortDirection, setSortDirection] = useState<string>('desc');
    const [sortFiled, setSortField] = useState<string>('date');
    const user: User | null = useSelector((state: any) => state.user.userInfo);
    const [dialogOpened, setDialogOpened] = useState<boolean>(false);

    const navigate = useNavigate();
    const [removeQuote, { data: removedQuote, error: removeError }] = useMutation(REMOVE_QUOTE, {
        refetchQueries: [{ query: GET_QUOTES }, 'getQuotes'],
    });
    const { showToast, Notification } = useToast();

    const { loading: loadingQuote, error, data: quoteInfo } = useQuery(GET_QUOTE_FORM_INFO);

    const [generatePDF, { data: generatedPDF, loading: generating, error: errorGenerating }] =
        useMutation(GENERATE_QUOTE_PDF);

    const [generateProformaInvoice, { data: invoice, error: invoiceError, loading: generatingInvoice }] =
        useMutation(GENERATE_PROFORMA_INVOICE);

    const { data: loadedQuotes, loading, error: quoteError } = useQuery(GET_QUOTES);

    useEffect(() => {
        const routeData: QuoteRouteData = getLocalStorage();

        if (routeData) {
            setOrderFilter(routeData?.keyWord || '');
            setPage(routeData?.page || 0);
            setRowsPerPage(routeData?.pageSize || 10);
        }
    }, []);

    const sortBy: any = (quotes: Quote[], field: string, direction: string) => {
        const orderedOrders = new Map([
            ['client', _.orderBy(quotes, (quote) => quote.client.companyName, [direction === 'asc' ? 'asc' : 'desc'])],
            ['date', _.orderBy(quotes, (quote) => moment(quote.createdAt), [direction === 'asc' ? 'asc' : 'desc'])],
            ['reference', _.orderBy(quotes, (quote) => quote.reference, [direction === 'asc' ? 'asc' : 'desc'])],
            ['status', _.orderBy(quotes, (quote) => quote.status.title, [direction === 'asc' ? 'asc' : 'desc'])],
            ['type', _.orderBy(quotes, (quote) => quote.type.title, [direction === 'asc' ? 'asc' : 'desc'])],
        ]);
        return orderedOrders.get(field);
    };

    const displayAlert = (props: any) => {
        setAlert(
            <SweetAlert
                {...props}
                showCancel
                confirmBtnText='Continue'
                confirmBtnBsStyle='danger'
                title='Are you sure?'
                onConfirm={() => {
                    removeQuote({
                        variables: { id: props.quote.id },
                    });
                    setAlert(null);
                }}
                onCancel={() => {
                    setAlert(null);
                }}
                focusCancelBtn>
                This quote will be removed from the system.
            </SweetAlert>,
        );
    };

    const getRowValue = (value: string) => {
        const newQuoteRouteData: QuoteRouteData = getLocalStorage() || {};
        setRowsPerPage(parseInt(value));
        newQuoteRouteData.pageSize = parseInt(value);
        if (parseInt(value) !== rowPerPage) {
            setPage(0);
            newQuoteRouteData.page = 0;
        }

        setLocalStorage(newQuoteRouteData);
    };

    useEffect(() => {
        if (generatedPDF) {
            console.log('PDF data', generatedPDF);
            const byteArray = new Uint8Array(
                atob(generatedPDF.response.pdf)
                    .split('')
                    .map((char) => char.charCodeAt(0)),
            );

            const blob = new Blob([byteArray], { type: 'application/pdf' });
            const url = window.URL.createObjectURL(blob);
            window.open(url, '_blank');
        }
    }, [generatedPDF]);

    useEffect(() => {
        if (invoiceError) {
            showToast('An error has occurred while trying to generate an invoice', 'danger');
        }
    }, [invoiceError]);

    useEffect(() => {
        if (invoice) {
            console.log('PDF data', invoice);
            const byteArray = new Uint8Array(
                atob(invoice.proformaInvoice.pdf)
                    .split('')
                    .map((char) => char.charCodeAt(0)),
            );

            const blob = new Blob([byteArray], { type: 'application/pdf' });
            const url = window.URL.createObjectURL(blob);
            window.open(url, '_blank');
        }
    }, [invoice]);

    const nextPage = () => {
        setPage(page + 1);
        const newQuoteRouteData: QuoteRouteData = getLocalStorage() || {};
        newQuoteRouteData.page = page + 1;
        setLocalStorage(newQuoteRouteData);
    };
    const previousPage = () => {
        setPage(page - 1);
        const newQuoteRouteData: QuoteRouteData = getLocalStorage() || {};
        newQuoteRouteData.page = page - 1;
        setLocalStorage(newQuoteRouteData);
    };

    useEffect(() => {
        if (removedQuote) {
            showToast('Quote has been removed successfully', 'success');
        }
    }, [removedQuote]);

    useEffect(() => {
        if (quoteError || error) {
            console.log('Error', quoteError);
        }

        if (removeError) {
            showToast('An error has occurred while trying to remove the ', 'danger');
        }

        if (errorGenerating) {
            showToast('An error has occurred while trying to generate the quote PDF', 'danger');
        }
    }, [quoteError, removeError, errorGenerating, error]);

    if (loading || loadingQuote) {
        return <LinearProgress />;
    }

    const quotes: Quote[] = loadedQuotes?.quote;

    let filteredOrders = quotes.filter((quote: Quote) => {
        if (orderFilter === '') return true;

        const isOrderFiltered =
            quote?.reference?.toLowerCase()?.includes(orderFilter.toLowerCase()) ||
            quote?.client?.companyName?.toLowerCase().includes(orderFilter.toLowerCase()) ||
            quote?.type?.title.toLowerCase()?.includes(orderFilter.toLowerCase()) ||
            quote?.status?.title.toLowerCase()?.includes(orderFilter.toLowerCase());
        return isOrderFiltered;
    });

    filteredOrders = sortBy(filteredOrders, sortFiled, sortDirection);

    const clients: Client[] = quoteInfo?.clients;
    const quoteStatus: QuoteStatus[] = quoteInfo?.status;
    const quoteTypes: QuoteType[] = quoteInfo?.quoteTypes;

    return (
        <>
            {alert}
            {Notification}
            <AddQuoteDialog
                clients={clients}
                quoteStatus={quoteStatus}
                quoteTypes={quoteTypes}
                isOpen={dialogOpened}
                handleClose={() => setDialogOpened(false)}
            />
            <LoaderDialog isOpen={generating || generatingInvoice} />
            <Container className='mt-4' fluid>
                <Row>
                    <Col>
                        <h1>Quotes</h1>
                    </Col>
                </Row>
                <Row className='mt-4'>
                    <div className='col'>
                        <Card>
                            <CardHeader className='border-0'>
                                <Row>
                                    <Col xs='6'>
                                        <h3 className='mb-0'>Quotes</h3>
                                    </Col>
                                    <Col className='text-right' xs='6'>
                                        <button
                                            onClick={() => setDialogOpened(true)}
                                            className='btn btn-round btn-sm btn-dark'
                                            color='default'
                                            id='tooltip969372949'>
                                            <span className='btn-inner--text'>Add quote</span>
                                        </button>
                                        <UncontrolledTooltip delay={0} target='tooltip969372949'>
                                            Add quote
                                        </UncontrolledTooltip>
                                    </Col>
                                </Row>
                                <Row className='mt-4 border-0'>
                                    <Col>
                                        <Input
                                            className='form-control w-25'
                                            type='text'
                                            placeholder='Search keyword...'
                                            value={orderFilter}
                                            onChange={(e) => {
                                                setOrderFilter(e.target.value);
                                                setPage(0);

                                                const newQuoteRouteData: QuoteRouteData = getLocalStorage() || {};
                                                newQuoteRouteData.keyWord = e.target.value;
                                                newQuoteRouteData.page = 0;
                                                setLocalStorage(newQuoteRouteData);
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </CardHeader>
                            <Table className='align-items-center table-flush' responsive>
                                <thead className='thead-light'>
                                    <tr>
                                        <th
                                            onClick={() => {
                                                setSortField('client');
                                                setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                            }}
                                            className='sort'
                                            data-sort='client'
                                            scope='col'>
                                            Client
                                        </th>
                                        <th
                                            onClick={() => {
                                                setSortField('date');
                                                setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                            }}
                                            className='sort'
                                            data-sort='date'
                                            scope='col'>
                                            Date
                                        </th>
                                        <th
                                            onClick={() => {
                                                setSortField('reference');
                                                setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                            }}
                                            className='sort'
                                            data-sort='reference'
                                            scope='col'>
                                            Reference
                                        </th>
                                        <th
                                            onClick={() => {
                                                setSortField('status');
                                                setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                            }}
                                            className='sort'
                                            data-sort='status'
                                            scope='col'>
                                            Status
                                        </th>
                                        <th
                                            onClick={() => {
                                                setSortField('type');
                                                setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                            }}
                                            className='sort'
                                            data-sort='paymentStatus'
                                            scope='col'>
                                            Quote type
                                        </th>
                                        <th scope='col' />
                                    </tr>
                                </thead>
                                <tbody className='list'>
                                    {filteredOrders
                                        ?.slice(page * rowPerPage, page * rowPerPage + rowPerPage)
                                        ?.map((quote) => {
                                            return (
                                                <tr
                                                    key={quote.id}
                                                    onClick={() => navigate(`/admin/quotes/details/${quote.id}`)}
                                                    style={{
                                                        cursor: 'pointer',
                                                    }}>
                                                    <td>{quote.client.companyName || quote.client.individualName}</td>
                                                    <td>{moment(quote.createdAt).format('yyyy-MM-DD')}</td>
                                                    <td>{quote.reference || 'N/A'}</td>
                                                    <td>{quote.status.title}</td>
                                                    <td>{quote.type.title}</td>
                                                    <td className='text-right'>
                                                        {getPermission(user, 'orders', 'read') && (
                                                            <Link
                                                                className='btn btn-info btn-sm'
                                                                to={`/admin/quotes/details/${quote.id}`}>
                                                                View
                                                            </Link>
                                                        )}

                                                        {getPermission(user, 'orders', 'read') && (
                                                            <Button
                                                                className='btn btn-outlined-info btn-sm'
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    generatePDF({
                                                                        variables: {
                                                                            quoteInfo: {
                                                                                quote_id: quote.id,
                                                                            },
                                                                        },
                                                                    });
                                                                }}
                                                                disabled={
                                                                    (quote.status.value !== 'active' &&
                                                                        quote.status.value !== 'accepted') ||
                                                                    generating
                                                                }>
                                                                <span>Quote</span>
                                                            </Button>
                                                        )}
                                                        {getPermission(user, 'orders', 'delete') && (
                                                            <Button
                                                                className='btn btn-danger btn-icon-only rounded-circle btn-sm'
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    displayAlert({ warning: true, quote });
                                                                }}>
                                                                <i className='fa-solid fa-trash-can'></i>
                                                            </Button>
                                                        )}
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                </tbody>
                            </Table>
                            <CardFooter className='py-4'>
                                <Row>
                                    <div className='col-4 d-flex'>
                                        <span style={{ fontSize: '12px' }}>Rows per page:</span>
                                        <BasicMenu
                                            getValue={getRowValue}
                                            options={['10', '20', '100']}
                                            value={rowPerPage.toString()}
                                        />
                                    </div>
                                    <div className='col-4 d-flex'>
                                        <span className='min-text'>Page:</span>
                                        <span className='min-text pl-2'>
                                            {page + 1} of {Math.ceil(filteredOrders.length / rowPerPage)}
                                        </span>
                                    </div>
                                    <div className='col-4 d-flex'>
                                        <Pagination>
                                            <PaginationItem className={page <= 0 ? 'disabled' : ''}>
                                                <PaginationLink onClick={(e) => previousPage()}>
                                                    <i className='fas fa-angle-left' />
                                                    <span className='sr-only'>Previous</span>
                                                </PaginationLink>
                                            </PaginationItem>
                                            <PaginationItem
                                                className={
                                                    page >= Math.ceil(filteredOrders.length / rowPerPage) - 1
                                                        ? 'disabled'
                                                        : ''
                                                }>
                                                <PaginationLink onClick={(e) => nextPage()}>
                                                    <i className='fas fa-angle-right' />
                                                    <span className='sr-only'>Previous</span>
                                                </PaginationLink>
                                            </PaginationItem>
                                        </Pagination>
                                    </div>
                                </Row>
                            </CardFooter>
                        </Card>
                    </div>
                </Row>
            </Container>
        </>
    );
};

export default QuoteListView;
