/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import {
    Card,
    CardFooter,
    CardHeader,
    Col,
    Input,
    Pagination,
    PaginationItem,
    PaginationLink,
    Row,
    Table,
} from 'reactstrap';
import BasicMenu from '../../../../components/Menu';
import { useQuery } from '@apollo/client';
import { GET_AGEING_REPORT_ITEMS } from '../../../../graphql/financial-report.graphql';
import useToast from '../../../../hooks/useToast';
import { LinearProgress } from '@mui/material';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import { AgeingReportItem } from '../../../../models/finance.report.model';
import { currencyFormatter } from '../../../../utils/format.utils';

export default function AgeingReportItemLitsView() {
    const params = useParams();
    const { data, loading, error } = useQuery(GET_AGEING_REPORT_ITEMS, {
        variables: {
            reportId: params.id,
        },
    });
    const [page, setPage] = useState<number>(0);
    const [rowPerPage, setRowPerPage] = useState<number>(10);
    const [sortDirection, setSortDirection] = useState<string>('asc');
    const [sortFiled, setSortField] = useState<string>('unitNumber');
    const [searchKey, setSearchKey] = useState<string>('');
    const { showToast, Notification } = useToast();

    const sortBy: any = (reports: AgeingReportItem[], field: string, direction: string) => {
        const orderedBookings = new Map([
            ['unitNumber', _.orderBy(reports, (report) => report.unitNumber, [direction === 'asc' ? 'asc' : 'desc'])],
            ['tenant', _.orderBy(reports, (report) => report.tenantName, [direction === 'asc' ? 'asc' : 'desc'])],
            [
                'oneTwentyPlusDays',
                _.orderBy(reports, (report) => report.oneTwentyPlusDays, [direction === 'asc' ? 'asc' : 'desc']),
            ],
            ['ninetyDays', _.orderBy(reports, (report) => report.ninetyDays, [direction === 'asc' ? 'asc' : 'desc'])],
            ['sixtyDays', _.orderBy(reports, (report) => report.sixtyDays, [direction === 'asc' ? 'asc' : 'desc'])],
            ['thirtyDays', _.orderBy(reports, (report) => report.thirtyDays, [direction === 'asc' ? 'asc' : 'desc'])],
            ['current', _.orderBy(reports, (report) => report.current, [direction === 'asc' ? 'asc' : 'desc'])],
            ['totalDue', _.orderBy(reports, (report) => report.totalDue, [direction === 'asc' ? 'asc' : 'desc'])],
        ]);
        return orderedBookings.get(field);
    };

    const getRowValue = (value: string) => {
        setRowPerPage(parseInt(value));
    };

    useEffect(() => {
        if (error) {
            showToast('An error has occurred while trying to load report records', 'danger');
        }
    }, [error]);

    const nextPage = () => {
        setPage(page + 1);
    };
    const previousPage = () => {
        setPage(page - 1);
    };

    if (loading) {
        return <LinearProgress />;
    }

    const reportItems: AgeingReportItem[] = data?.reportItems;
    let reportFilteredItems = reportItems.filter((item) => {
        if (searchKey === '') {
            return item;
        }

        const isFiltered =
            item.unitNumber?.toLowerCase().includes(searchKey.toLowerCase()) ||
            item.tenantName?.toLowerCase().includes(searchKey.toLowerCase()) ||
            item.oneTwentyPlusDays?.toString().toLowerCase().includes(searchKey.toLowerCase()) ||
            item.ninetyDays?.toString().toLowerCase().includes(searchKey.toLowerCase()) ||
            item.sixtyDays?.toString().toLowerCase().includes(searchKey.toLowerCase()) ||
            item.thirtyDays?.toString().toLowerCase().includes(searchKey.toLowerCase()) ||
            item.current?.toString().toLowerCase().includes(searchKey.toLowerCase()) ||
            item.totalDue?.toString().toLowerCase().includes(searchKey.toLowerCase());

        return isFiltered;
    });

    reportFilteredItems = sortBy(reportFilteredItems, sortFiled, sortDirection);

    return (
        <>
            {Notification}
            <Row className='mt-4'>
                <div className='col'>
                    <Card>
                        <CardHeader className='border-0'>
                            <Row>
                                <Col xs='6'>
                                    <h3 className='mb-0'>Ageing Report Records</h3>
                                </Col>
                            </Row>
                            <Row className='mt-4'>
                                <Col xs='6'>
                                    <Input
                                        className='form-control w-50'
                                        type='text'
                                        value={searchKey}
                                        placeholder='Search keyword...'
                                        onChange={(e) => {
                                            setSearchKey(e.target.value);
                                        }}
                                    />
                                </Col>
                                <Col xs='6'></Col>
                            </Row>
                        </CardHeader>
                        <Table className='align-items-center table-flush' responsive>
                            <thead className='thead-light'>
                                <tr>
                                    <th
                                        onClick={() => {
                                            setSortField('unitNumber');
                                            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                        }}
                                        className='sort'
                                        data-sort='name'
                                        scope='col'>
                                        Unit no.
                                    </th>
                                    <th
                                        onClick={() => {
                                            setSortField('tenant');
                                            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                        }}
                                        className='sort'
                                        data-sort='location'
                                        scope='col'>
                                        Tenant
                                    </th>
                                    <th
                                        onClick={() => {
                                            setSortField('oneTwentyPlusDays');
                                            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                        }}
                                        className='sort'
                                        data-sort='location'
                                        scope='col'>
                                        120+ days
                                    </th>
                                    <th
                                        onClick={() => {
                                            setSortField('ninetyDays');
                                            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                        }}
                                        className='sort'
                                        data-sort='location'
                                        scope='col'>
                                        90 days
                                    </th>
                                    <th
                                        onClick={() => {
                                            setSortField('sixtyDays');
                                            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                        }}
                                        className='sort'
                                        data-sort='location'
                                        scope='col'>
                                        60 days
                                    </th>
                                    <th
                                        onClick={() => {
                                            setSortField('thirtyDays');
                                            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                        }}
                                        className='sort'
                                        data-sort='location'
                                        scope='col'>
                                        30 days
                                    </th>
                                    <th
                                        onClick={() => {
                                            setSortField('current');
                                            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                        }}
                                        className='sort'
                                        data-sort='location'
                                        scope='col'>
                                        Current
                                    </th>
                                    <th
                                        onClick={() => {
                                            setSortField('totalDue');
                                            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                        }}
                                        className='sort'
                                        data-sort='location'
                                        scope='col'>
                                        Total due
                                    </th>
                                </tr>
                            </thead>
                            <tbody className='list'>
                                {reportFilteredItems
                                    .slice(page * rowPerPage, page * rowPerPage + rowPerPage)
                                    .map((item) => {
                                        return (
                                            <tr key={item.id}>
                                                <td>{item.unitNumber}</td>
                                                <td>{item.tenantName}</td>
                                                <td>{currencyFormatter(item.oneTwentyPlusDays)}</td>
                                                <td>{currencyFormatter(item.ninetyDays)}</td>
                                                <td>{currencyFormatter(item.sixtyDays)}</td>
                                                <td>{currencyFormatter(item.thirtyDays)}</td>
                                                <td>{currencyFormatter(item.current)}</td>
                                                <td>{currencyFormatter(item.totalDue)}</td>
                                            </tr>
                                        );
                                    })}
                            </tbody>
                        </Table>
                        <CardFooter className='py-4'>
                            <Row>
                                <div className='col-4 d-flex'>
                                    <span className='min-text'>Rows per page:</span>
                                    <BasicMenu
                                        getValue={getRowValue}
                                        options={['10', '20', '100']}
                                        value={rowPerPage.toString()}
                                    />
                                </div>

                                <div className='col-4 d-flex'>
                                    <span className='min-text'>Page:</span>
                                    <span className='min-text pl-2'>
                                        {page + 1} of {Math.ceil(reportFilteredItems.length / rowPerPage)}
                                    </span>
                                </div>
                                <div className='col-4 d-flex'>
                                    <Pagination>
                                        <PaginationItem className={page <= 0 ? 'disabled' : ''}>
                                            <PaginationLink onClick={(e) => previousPage()}>
                                                <i className='fas fa-angle-left' />
                                                <span className='sr-only'>Previous</span>
                                            </PaginationLink>
                                        </PaginationItem>
                                        <PaginationItem
                                            className={
                                                page >= Math.ceil(reportFilteredItems.length / rowPerPage) - 1
                                                    ? 'disabled'
                                                    : ''
                                            }>
                                            <PaginationLink onClick={(e) => nextPage()}>
                                                <i className='fas fa-angle-right' />
                                                <span className='sr-only'>Previous</span>
                                            </PaginationLink>
                                        </PaginationItem>
                                    </Pagination>
                                </div>
                            </Row>
                        </CardFooter>
                    </Card>
                </div>
            </Row>
        </>
    );
}
