/* eslint-disable react-hooks/exhaustive-deps */
import { useMutation, useQuery } from '@apollo/client';
import React, { useEffect } from 'react';
import ReactDatetimeClass from 'react-datetime';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { Button, Card, CardBody, CardHeader, Col, FormGroup, Row } from 'reactstrap';
import {
    GET_AGEING_REPORT,
    GET_AGEING_REPORT_LIST,
    UPDATE_AGEING_REPORT,
} from '../../../../graphql/financial-report.graphql';
import useToast from '../../../../hooks/useToast';
import { LinearProgress } from '@mui/material';
import { AgeingReport } from '../../../../models/finance.report.model';
import Spinner from '../../../../components/Spinner/Spinner';

type FormData = {
    title: string;
    date: string;
};

export default function AgeingReportDetailsForm() {
    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm<FormData>();

    const params = useParams();

    const { data, loading, error } = useQuery(GET_AGEING_REPORT, {
        variables: {
            id: params.id,
        },
    });
    const { showToast, Notification } = useToast();
    const [updateAgeingReport, { data: updatedReport, loading: updating, error: updateError }] = useMutation(
        UPDATE_AGEING_REPORT,
        { refetchQueries: [GET_AGEING_REPORT, { query: GET_AGEING_REPORT_LIST }] },
    );

    useEffect(() => {
        if (error) {
            showToast('An error has occurred while trying to load the page', 'danger');
            console.log('An error has occurred why loading th page', error);
        }
    }, [error]);

    useEffect(() => {
        if (updatedReport) {
            showToast('Successfully updated ageing report details', 'success');
        }
    }, [updatedReport]);

    useEffect(() => {
        if (updateError) {
            showToast('An error has occurred while trying to update report details', 'danger');
            console.log('Update error', updateError);
        }
    }, [updateError]);

    const onSubmit = (formData: FormData) => {
        const report = {
            name: formData.title,
            date: formData.date,
        };

        updateAgeingReport({
            variables: {
                id: params.id,
                report,
            },
        });
    };

    if (loading) {
        return <LinearProgress />;
    }

    const report: AgeingReport = data?.report;
    return (
        <>
            {Notification}
            <Row className='mt-4'>
                <Col md='12' lg='6'>
                    <Card>
                        <CardHeader>
                            <Row noGutters={true}>
                                <h3 className='m-0'>Ageing Report Details</h3>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className='row'>
                                    <div className='col-md-12'>
                                        <FormGroup>
                                            <label className='form-control-label' htmlFor='subject'>
                                                Title
                                            </label>
                                            <input
                                                className='form-control'
                                                {...register('title', { required: true, value: report.name })}
                                                id='subject'
                                                placeholder='Enter ageing report title...'
                                                type='text'
                                            />
                                        </FormGroup>
                                        {errors.title && <span className='invalid'>*This field is required</span>}
                                    </div>
                                    <div className='col-md-12'>
                                        <FormGroup>
                                            <label className='form-control-label' htmlFor='name'>
                                                Date
                                            </label>
                                            <ReactDatetimeClass
                                                inputProps={{
                                                    placeholder: 'Enter starting date...',
                                                    autoComplete: 'off',
                                                    ...register('date', { required: true, value: report.date }),
                                                }}
                                                dateFormat={'YYYY-MM-DD'}
                                                initialValue={report.date}
                                                onChange={(date: moment.Moment) => {
                                                    setValue('date', date.format('YYYY-MM-DD'));
                                                }}
                                                timeFormat={false}
                                            />
                                        </FormGroup>
                                    </div>
                                </div>
                                <div className='d-flex h-100'>
                                    <div className='align-self-end ml-auto'>
                                        <Button
                                            type='submit'
                                            className='btn btn-dark mt-4 btn-block'
                                            disabled={updating}>
                                            {!updating && <span>Update</span>}
                                            {updating && (
                                                <span>
                                                    <Spinner />
                                                </span>
                                            )}
                                        </Button>
                                    </div>
                                </div>
                            </form>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    );
}
