/* eslint-disable react-hooks/exhaustive-deps */
import {
    Autocomplete,
    Checkbox,
    Chip,
    FormControl,
    FormControlLabel,
    IconButton,
    Input,
    MenuItem,
    RadioGroup,
    Select,
    TextField,
} from '@mui/material';
import GroupsIcon from '@mui/icons-material/Groups';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import React, { useEffect, useRef, useState } from 'react';
import { Col, Row, Spinner } from 'reactstrap';
import NoteOutlinedIcon from '@mui/icons-material/NoteOutlined';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import { bookingTimeList } from '../../variables/variables';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import EmailChipListInput from '../../views/pages/clients/components/EmailChipListInput';
import CloseIcon from '@mui/icons-material/Close';
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';
import CalendarTodayRoundedIcon from '@mui/icons-material/CalendarTodayRounded';
import NotesIcon from '@mui/icons-material/Notes';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import {
    Booking,
    BookingFormData,
    BookingGuest,
    BookingRate,
    BookingRoomExtra,
    BookingStatus,
    ConferenceBookingDocument,
    ConferenceBookingNote,
    NominatedMember,
} from '../../models/bookings.model';
import { GET_CLIENT_TEAM_MEMBERS, SEARCH_ACCOUNTS } from '../../graphql/clients.graphql';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { MeetingRoomItem, MeetingRoomItemRules } from '../../models/meeting.room.model';
import { AccountSearchQueryResult } from '../../models/client.model';
import BookingAddOnOption from '../BookingAddOnOption';
import { MenuType, Vendor, VendorMenuPackageItem } from '../../models/vendor.model';
import ShieldOutlinedIcon from '@mui/icons-material/ShieldOutlined';
import { AfterHourResource, BookingResource } from '../../models/resources.model';
import { getTime } from '../../utils/booking.utils';
import { CHECK_IS_CLOSED_TIME } from '../../graphql/meeting-rooms.graphql';
import { REMOVE_BOOKING_GUEST, REMOVE_NOMINATED_MEMBER } from '../../graphql/bookings.graphql';

import { TeamMember } from '../../models/team.member.model';
import DiscountOutlinedIcon from '@mui/icons-material/DiscountOutlined';
import { CHECK_IS_VOUCHER_VALID } from '../../graphql/vouchers.graphql';
import useToast from '../../hooks/useToast';

interface ExtendedBookingFormProps {
    clientId?: string;
    sendEmail: boolean;

    getSendEmail: (value: boolean) => void;
    setIsClosedDate: (value: boolean) => void;
    getVoucherId: (voucherId: string) => void;
    vendors?: Vendor[];
    vendorMenuTypes?: MenuType[];
    onFormSubmit?: () => void;
    onClose: () => void;
    rooms: MeetingRoomItem[];
    statusList?: BookingStatus[];
    selectedRoomId?: string;
    isRoomAvailable?: boolean;
    selectedBooking?: Booking;
    formData: BookingFormData;
    checkingAvailability?: boolean;
    addingBooking?: boolean;
    addingQuote?: boolean;
    getFormData?: (data: BookingFormData) => void;
    availabilityText: string;
    bookingRate?: BookingRate;
    getSelectedMenuItems?: (items: VendorMenuPackageItem[]) => void;
    selectedMenuItems?: VendorMenuPackageItem[];
    getConfigurationId?: (id: string) => void;
    configurationId?: string;
    afterHourResources: AfterHourResource[];
    getBookingResources?: (resources: BookingResource[]) => void;
    bookingResources: BookingResource[];
    nominatedMembers: NominatedMember[];
    getNominatedMembers: (members: NominatedMember[]) => void;
    bookingRoomAdOns: BookingRoomExtra[];
    getBookingRoomAdOns: (addOns: BookingRoomExtra[]) => void;
    documents: ConferenceBookingDocument[];
    getDocuments: (documents: ConferenceBookingDocument[]) => void;
    notes: ConferenceBookingNote[];
    getNotes: (notes: ConferenceBookingNote[]) => void;
}

const ExtendedBookingForm = ({
    clientId,
    getVoucherId,
    vendors,
    setIsClosedDate,
    vendorMenuTypes,
    onFormSubmit,
    rooms,
    selectedBooking,
    isRoomAvailable,
    statusList,
    checkingAvailability,
    onClose,
    getFormData,
    addingBooking,
    addingQuote,
    selectedRoomId,
    availabilityText,
    bookingRate,
    formData,
    getSelectedMenuItems,
    selectedMenuItems,
    getConfigurationId,
    configurationId,
    afterHourResources,
    getBookingResources,
    bookingResources,
    nominatedMembers,
    getNominatedMembers,
    getSendEmail,
    sendEmail,
    bookingRoomAdOns,
    getBookingRoomAdOns,
    documents,
    getDocuments,
    notes,
    getNotes,
}: ExtendedBookingFormProps) => {
    const [guests, setGuests] = useState<BookingGuest[]>([]);
    const [query, setQuery] = useState<string>();
    const [checklistIdList, setChecklistIdList] = useState<string[]>([]);
    const [accounts, setAccounts] = useState<AccountSearchQueryResult[]>([]);
    const [availableMembers, setAvailableMembers] = useState<TeamMember[]>([]);
    const [validText, setValidText] = useState<string>('');
    const { showToast, Notification } = useToast();
    const { data: membersData } = useQuery(GET_CLIENT_TEAM_MEMBERS, {
        variables: {
            accountId: clientId,
        },
        skip: !clientId,
    });
    const [searchAccounts, { data, called }] = useLazyQuery(SEARCH_ACCOUNTS);
    const [isBookingInClosedDate, setIsBookingInClosedDate] = useState<boolean>(false);

    const [checkBookingClosedDate, { data: closedDateData, error }] = useMutation(CHECK_IS_CLOSED_TIME);

    const [removedNominatedMember, { error: memberError }] = useMutation(REMOVE_NOMINATED_MEMBER);

    const [checkIsVoucherValid, { loading: checking, data: voucherData, error: checkError }] =
        useMutation(CHECK_IS_VOUCHER_VALID);

    const [deleteBookingGuest] = useMutation(REMOVE_BOOKING_GUEST);

    const defaultFormData = () => {
        if (selectedBooking && !formData) {
            return {
                title: selectedBooking?.subject || '',
                client: selectedBooking?.client.id || '',
                date: selectedBooking?.from ? moment(selectedBooking.from).toISOString() : moment().toISOString(),
                start: 'empty',
                end: 'empty',
                status: selectedBooking?.status?.value || 'empty',
                accessCode: selectedBooking?.accessCode || '',
                voucherCode: selectedBooking?.voucherCode || '',
                sendCommunication: selectedBooking?.sendCommunication || false,
                includeCalendarInvites: selectedBooking?.includeCalendarInvites || false,
                meetingRoomId: selectedBooking?.meetingRoomItem.id || 'empty',
                description: selectedBooking?.additionalNotes || '',
                longTermEndDate: selectedBooking ? moment(selectedBooking?.to).toISOString() : moment().toISOString(),
                isFullDay: selectedBooking?.isLongTerm || false,
                recurringPeriod: selectedBooking?.recurringSchedule.period || 'none',
                customPrice: selectedBooking?.quotedPrice || 0,
                isRecurringForm: selectedBooking?.recurringSchedule.isRecurring || false,
                isCustomPricing: selectedBooking?.hasCustomPricing,
                billingPeriod: selectedBooking?.billingPeriod,
            };
        }

        return {
            title: formData?.title || '',
            client: formData?.client || '',
            date: formData?.date ? moment(formData?.date).toISOString() : moment().toISOString(),
            start: formData?.start || 'empty',
            end: formData?.end || 'empty',
            status: formData?.status || 'empty',
            accessCode: formData?.accessCode || '',
            voucherCode: formData?.voucherCode || '',
            sendCommunication: formData?.sendCommunication || false,
            includeCalendarInvites: formData?.includeCalendarInvites || false,
            meetingRoomId: formData?.meetingRoomId || 'empty',
            description: formData?.description || '',
            longTermEndDate: selectedBooking ? moment(selectedBooking?.to).toISOString() : moment().toISOString(),
            isFullDay: formData?.isFullDay || false,
            recurringPeriod: formData?.recurringPeriod || 'none',
            isRecurringForm: formData?.isRecurringForm || false,
            isCustomPricing: formData?.isCustomPricing || false,
            customPrice: formData?.customPrice,
            billingPeriod: formData?.billingPeriod,
        };
    };

    const {
        register,
        handleSubmit,
        getValues,
        watch,
        setValue,
        trigger,
        formState: { errors },
    } = useForm<BookingFormData>({
        defaultValues: defaultFormData(),
    });
    const formRef = useRef<any>(null);

    useEffect(() => {
        if (query && query.length >= 3) {
            searchAccounts({ variables: { query: `%${query}%` } });
        }

        if (called && data) {
            setAccounts(data.accounts);
        }

        if (selectedBooking && !formData) {
            setGuests(selectedBooking?.guests);
            setValue('start', moment(selectedBooking.from).format('HH:mm'));
            setValue('end', moment(selectedBooking.to).format('HH:mm'));
            checkTime();
            if (accounts.length === 0) setAvailableMembers(selectedBooking.client?.members || []);
        }

        if (selectedRoomId && !formData) {
            setValue('meetingRoomId', selectedRoomId);
            setValue('accessCode', selectedBooking?.accessCode);
            setValue('voucherCode', selectedBooking?.voucherCode);
        }

        if (membersData) {
            setAvailableMembers(membersData?.members);
        }

        if (checklistIdList) {
            updateFormData();
        }

        if (
            watch('customPrice') ||
            watch('isCustomPricing') ||
            watch('accessCode') ||
            watch('sendCommunication') ||
            watch('title') ||
            watch('voucherCode') ||
            watch('includeCalendarInvites')
        ) {
            updateFormData();
        }
    }, [
        query,
        searchAccounts,
        data,
        selectedBooking,
        selectedRoomId,
        checklistIdList,
        membersData,
        watch('customPrice'),
        watch('isCustomPricing'),
        watch('accessCode'),
        watch('title'),
        watch('sendCommunication'),
        watch('includeCalendarInvites'),
        watch('voucherCode'),
    ]);

    useEffect(() => {
        if (voucherData) {
            if (!voucherData?.voucherResults.isValid) {
                showToast(voucherData?.voucherResults.message, 'danger');
                setValidText('Invalid');
            }

            if (voucherData?.voucherResults.isValid) {
                setValidText('Valid');
                getVoucherId(voucherData?.voucherResults.voucherId);
            }
        }

        if (checkError) {
            console.log('Check valid voucher error', checkError);
        }
    }, [voucherData, checkError]);

    useEffect(() => {
        updateFormData();
        console.log('Guests', guests);
    }, [guests]);

    const isDayBeforeToday = (current: moment.Moment): boolean => {
        const yesterday: moment.Moment = moment().subtract(1, 'day');
        return current.isBefore(yesterday) || isWeekend(current);
    };

    const isWeekend = (current: moment.Moment) => {
        return current.format('dddd') === 'Saturday' || current.format('dddd') === 'Sunday';
    };

    useEffect(() => {
        if (error) {
            console.log('Oops, error', error);
        }

        if (closedDateData) {
            setIsBookingInClosedDate(closedDateData?.closeDate?.isClosedTime);
            setIsClosedDate(closedDateData?.closeDate?.isClosedTime);
        }

        if (memberError) {
            console.log('Oops, error', memberError);
        }
    }, [closedDateData, error, memberError]);

    const updateFormData = () => {
        const formData: BookingFormData = {
            title: watch('title'),
            client: watch('client'),
            date: watch('date'),
            longTermEndDate: watch('longTermEndDate'),
            endDate: watch('endDate'),
            start: watch('start'),
            end: watch('end'),
            isFullDay: watch('isFullDay'),
            recurringPeriod: watch('recurringPeriod'),
            meetingRoomId: watch('meetingRoomId'),
            isRecurringForm: watch('isRecurringForm'),
            guests: guests.map((guest) => guest.email),
            description: watch('description'),
            status: watch('status'),
            isCustomPricing: watch('isCustomPricing'),
            billingPeriod: watch('billingPeriod'),
            customPrice: watch('customPrice'),
            checklistItems: checklistIdList,
            accessCode: getValues('accessCode'),
            sendCommunication: watch('sendCommunication'),
            voucherCode: watch('voucherCode'),
            includeCalendarInvites: watch('includeCalendarInvites'),
        };

        getFormData({ ...formData });
    };

    const onAddGuest = (guest: BookingGuest) => {
        // console.log('Adding guest...');
        const index = guests.findIndex((item) => item.email === guest.email);
        if (index === -1) {
            setGuests([...guests, guest]);
        }
    };

    const removeGuest = (guest: BookingGuest) => {
        if (guest.id) {
            deleteBookingGuest({
                variables: {
                    guestId: guest.id,
                },
            });
        }

        console.log('Guest', guest.id);
        const newGuests = guests.filter((item) => item.email !== guest.email);
        setGuests(newGuests);
    };

    const onSubmit = async (data: BookingFormData) => {
        if (onFormSubmit) {
            updateFormData();
            onFormSubmit();
        }
    };

    const onAddMember = (member: NominatedMember) => {
        const memberIndex = nominatedMembers.findIndex((m) => m.id === member.id);
        if (memberIndex !== -1) {
            return;
        }

        const members = [...nominatedMembers, member];
        getNominatedMembers(members);
    };

    const onRemoveMember = (member: NominatedMember) => {
        const members = [...nominatedMembers.filter((m) => m.id !== member.id)];
        getNominatedMembers(members);
    };

    const meetingRoomItemRules: MeetingRoomItemRules | undefined = rooms.find(
        (room) => room.id === getValues('meetingRoomId'),
    )?.rules[0];

    const defaultClientValue = () => {
        if (selectedBooking && !formData) {
            return {
                id: selectedBooking?.client.id,
                companyName: selectedBooking?.client?.companyName,
                individualName: selectedBooking?.client?.individualName,
            };
        }

        return null;
    };

    const handleClose = () => {
        updateFormData();
        onClose();
    };

    const perRateString = (unit: string) => {
        const rates = new Map<string, string>();

        rates.set('hourly', 'per hour');
        rates.set('half_day', 'per half day');
        rates.set('full_day', 'per day');
        rates.set('once_off', 'once off');
        rates.set('monthly', 'per month');

        if (!rates.has(unit)) {
            return '';
        }

        return rates.get(unit);
    };

    const checkTime = () => {
        if (watch('start') === 'empty' || watch('end') === 'empty') return;

        const startTime = moment(watch('date'))
            .utc(true)
            .hour(getTime(watch('start'))[0])
            .minute(0);

        const endTime = moment(watch('date'))
            .utc(true)
            .hour(getTime(watch('end'))[0])
            .minute(0);

        checkBookingClosedDate({
            variables: {
                args: {
                    time_from: startTime.toISOString(),
                    time_to: endTime.toISOString(),
                },
            },
        });
    };

    const bookingHours = (): number => {
        const startTime = moment(watch('date'))
            .utc(true)
            .hour(getTime(watch('start'))[0])
            .minute(0);

        const endTime = moment(watch('date'))
            .utc(true)
            .hour(getTime(watch('end'))[0])
            .minute(0);

        return endTime.diff(startTime, 'hours');
    };

    return (
        <form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
            <div className='row'>
                {Notification}
                <div className={'col-md-12 col-lg-6'}>
                    <div className='row'>
                        <div className='col-1'>
                            <IconButton edge='start' color='inherit' onClick={handleClose} aria-label='close'>
                                <CloseIcon sx={{ color: 'black', fontSize: '24px' }} />
                            </IconButton>
                        </div>
                        <div className='col-10'>
                            <Input
                                id='title'
                                placeholder='Add title'
                                {...register('title', {
                                    required: true,
                                    value: selectedBooking?.subject,
                                })}
                                fullWidth={true}
                                style={{ fontSize: '22px' }}
                            />
                            {errors.title && <span className='invalid'>*This field is required</span>}
                        </div>
                    </div>
                    <div className='row mt-4'>
                        {!clientId && (
                            <div className='col-1 align-self-center'>
                                <GroupsIcon sx={{ fontSize: '24px' }} />
                            </div>
                        )}
                        {!clientId && (
                            <div className='col-10'>
                                <Autocomplete
                                    id='client'
                                    {...register('client', {
                                        required: false,
                                    })}
                                    onChange={(event, value) => {
                                        const _value: any = value?.id;
                                        setValue('client', _value);
                                        if (nominatedMembers.length > 0) getNominatedMembers([]);

                                        const teamMembers = accounts.find((acc) => acc.id === _value)?.members || [];
                                        setAvailableMembers(teamMembers);

                                        const includesInvites =
                                            accounts.find((acc) => acc.id === _value)?.includeCalendarInvites || false;

                                        console.log(
                                            'Invites',
                                            accounts.find((acc) => acc.id === _value)?.includeCalendarInvites,
                                        );

                                        setValue('includeCalendarInvites', includesInvites);

                                        updateFormData();
                                        // calculateRate();
                                    }}
                                    onInputChange={(event, value) => {
                                        setQuery(value);
                                    }}
                                    defaultValue={defaultClientValue()}
                                    filterOptions={(options) => options}
                                    options={accounts}
                                    getOptionLabel={(option) => option.companyName || option.individualName}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            placeholder='Select client'
                                            fullWidth={true}
                                            variant='standard'
                                            style={{ fontSize: '14px' }}
                                        />
                                    )}
                                />
                            </div>
                        )}
                    </div>
                    <div className='row mt-4'>
                        <div className='col-1 align-self-center'></div>
                        <div className='col-10'>
                            <Select
                                variant='standard'
                                {...register('member', { required: false })}
                                sx={{ width: '100%' }}
                                placeholder='Select member'
                                defaultValue={'empty'}
                                onChange={(e) => {
                                    const nominatedMember: NominatedMember = {
                                        id: availableMembers.find((member) => member.id === e.target.value)?.id,
                                        name: availableMembers.find((member) => member.id === e.target.value)?.user
                                            ?.name,
                                        surname: availableMembers.find((member) => member.id === e.target.value)?.user
                                            ?.surname,
                                    };

                                    onAddMember(nominatedMember);
                                }}>
                                <MenuItem disabled value={'empty'}>
                                    <span className='placeholder-text'>Select member</span>
                                </MenuItem>
                                {availableMembers.map((member) => {
                                    return (
                                        <MenuItem
                                            key={member?.id}
                                            value={member?.id}>{`${member.user.name} ${member.user.surname}`}</MenuItem>
                                    );
                                })}
                            </Select>
                            {nominatedMembers?.map((member) => {
                                return (
                                    <React.Fragment key={member.id}>
                                        <Chip
                                            sx={{ marginTop: 1 }}
                                            label={`${member.name} ${member.surname}`}
                                            onDelete={() => {
                                                removedNominatedMember({
                                                    variables: {
                                                        memberId: member.id,
                                                    },
                                                });
                                                onRemoveMember(member);
                                            }}
                                        />
                                    </React.Fragment>
                                );
                            })}
                        </div>
                    </div>
                </div>
                <div className={'col-md-12 col-lg-6'}>
                    <button
                        className='btn btn-primary'
                        disabled={
                            addingBooking ||
                            addingQuote ||
                            checkingAvailability ||
                            (!isRoomAvailable && !selectedBooking) ||
                            !isRoomAvailable ||
                            selectedBooking?.requiresVerification ||
                            sendEmail
                        }>
                        {!addingBooking && !addingQuote ? (
                            <span>Save</span>
                        ) : (
                            <span>{sendEmail ? 'Save' : <Spinner />}</span>
                        )}
                    </button>
                    {checkingAvailability && (
                        <span>
                            <Spinner />
                        </span>
                    )}
                    {selectedBooking?.requiresVerification && (
                        <p
                            style={{
                                color: 'red',
                                fontSize: 14,
                                paddingRight: 12,
                                marginTop: 16,
                            }}>
                            Booking Changes Under Review
                        </p>
                    )}
                    {!checkingAvailability && (
                        <span
                            style={{
                                color: isRoomAvailable ? 'blue' : 'red',
                                fontSize: 14,
                                paddingLeft: 10,
                                marginTop: 16,
                            }}>
                            {availabilityText}
                        </span>
                    )}
                </div>
            </div>
            <div className='row mt-4'>
                <div className={'col-md-12 col-lg-6'}>
                    <div className='row'>
                        <div className='col-1 align-self-center'>
                            <AccessTimeIcon sx={{ fontSize: '24px' }} />
                        </div>
                        <div className='col-10'>
                            <div className='row no-gutters'>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <DatePicker
                                        slotProps={{
                                            textField: {
                                                variant: 'standard',
                                                style: { fontSize: '14px' },
                                            },
                                        }}
                                        value={moment(watch('date'))}
                                        format='dddd, MMMM DD'
                                        {...register('date', {
                                            required: false,
                                            value: '',
                                        })}
                                        onChange={(newValue) => {
                                            setValue('date', newValue.toISOString());
                                            updateFormData();
                                            checkTime();
                                        }}
                                    />
                                </LocalizationProvider>
                                {watch('isFullDay') && <span className='mx-3'> - </span>}
                                {watch('isFullDay') && (
                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                        <DatePicker
                                            slotProps={{
                                                textField: {
                                                    variant: 'standard',
                                                    style: { fontSize: '14px' },
                                                },
                                            }}
                                            {...register('longTermEndDate', { required: false })}
                                            value={moment(watch('longTermEndDate'))}
                                            format='dddd, MMMM DD'
                                            onChange={(newValue) => {
                                                setValue('longTermEndDate', newValue.toISOString());
                                                updateFormData();
                                            }}
                                        />
                                    </LocalizationProvider>
                                )}
                                {!watch('isFullDay') && (
                                    <FormControl className='ml-4' sx={{ minWidth: 60 }}>
                                        <Select
                                            id='start'
                                            variant='standard'
                                            value={watch('start')}
                                            onChange={() => {
                                                updateFormData();
                                                checkTime();
                                                // calculateRate();
                                            }}
                                            inputProps={{
                                                ...register('start', {
                                                    required: true,
                                                    value: '',
                                                }),
                                            }}>
                                            <MenuItem disabled value={'empty'}>
                                                <span className='placeholder-text'>From...</span>
                                            </MenuItem>
                                            {bookingTimeList
                                                .filter((time) => {
                                                    return bookingTimeList.indexOf(time) !== bookingTimeList.length - 1;
                                                })
                                                .map((time) => {
                                                    return (
                                                        <MenuItem key={time} value={time}>
                                                            {time}
                                                        </MenuItem>
                                                    );
                                                })}
                                        </Select>
                                    </FormControl>
                                )}
                                {!watch('isFullDay') && <span className='mx-3'> - </span>}
                                {!watch('isFullDay') && (
                                    <FormControl sx={{ minWidth: 60 }}>
                                        <Select
                                            id='end'
                                            variant='standard'
                                            value={watch('end')}
                                            onChange={() => {
                                                updateFormData();
                                                checkTime();
                                                // calculateRate();
                                            }}
                                            inputProps={{
                                                ...register('end', {
                                                    required: false,
                                                    value: '',
                                                }),
                                            }}>
                                            <MenuItem disabled value={'empty'}>
                                                <span className='placeholder-text'>To...</span>
                                            </MenuItem>
                                            {bookingTimeList
                                                .filter((time) => {
                                                    const startTimeIndex = bookingTimeList.indexOf(getValues('start'));

                                                    return bookingTimeList.indexOf(time) > startTimeIndex;
                                                })
                                                .map((time) => {
                                                    return (
                                                        <MenuItem key={time} value={time}>
                                                            {time}
                                                        </MenuItem>
                                                    );
                                                })}
                                        </Select>
                                    </FormControl>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className='row mt-2'>
                        <div className='col-1 align-self-center'></div>
                        <div className='col-10'></div>
                    </div>
                    <div className='row'>
                        <div className='col-1 align-self-center'></div>
                        <div className='col-10'>
                            {meetingRoomItemRules?.allowRepeating && !selectedBooking?.id && (
                                <FormControl sx={{ minWidth: 120 }} size='small'>
                                    <Select
                                        labelId='demo-select-small-label'
                                        id='recurringPeriod'
                                        variant='standard'
                                        sx={{ fontSize: '14px' }}
                                        value={watch('recurringPeriod')}
                                        onChange={() => updateFormData()}
                                        inputProps={{
                                            ...register('recurringPeriod', {
                                                required: true,
                                            }),
                                        }}>
                                        <MenuItem value='none'>Does not repeat</MenuItem>
                                        <MenuItem value='daily'>{`Daily`}</MenuItem>
                                        <MenuItem value='weekly'>{`Weekly on ${moment(watch('date')).format(
                                            'dddd',
                                        )}`}</MenuItem>
                                    </Select>
                                </FormControl>
                            )}
                            {watch('recurringPeriod') !== 'none' && (
                                <div className='mt-2'>
                                    <p className='mt-4'>Ends</p>
                                    <FormControl>
                                        <RadioGroup
                                            aria-labelledby='demo-radio-buttons-group-label'
                                            defaultValue='female'
                                            name='radio-buttons-group'>
                                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                                <DatePicker
                                                    slotProps={{
                                                        textField: {
                                                            variant: 'standard',
                                                            style: { fontSize: '14px' },
                                                        },
                                                    }}
                                                    shouldDisableDate={isDayBeforeToday}
                                                    format='dddd, MMMM DD'
                                                    {...register('endDate', { required: false })}
                                                    onChange={(newValue) => {
                                                        setValue('endDate', newValue.toISOString());
                                                        updateFormData();
                                                    }}
                                                />
                                            </LocalizationProvider>
                                        </RadioGroup>
                                    </FormControl>
                                </div>
                            )}
                        </div>
                    </div>
                    {selectedBooking?.id && (
                        <div className='row mt-4'>
                            <div className='col-1 align-self-center'>
                                <NoteOutlinedIcon sx={{ fontSize: '24px' }} />
                            </div>
                            <div className='col-10'>
                                <FormControl sx={{ minWidth: 200 }}>
                                    <Select
                                        labelId='room-label'
                                        id='meetingRoomId'
                                        placeholder='Select booking status'
                                        variant='standard'
                                        value={watch('status')}
                                        sx={{ fontSize: '14px' }}
                                        onChange={(e) => {
                                            updateFormData();
                                        }}
                                        inputProps={{
                                            ...register('status', {
                                                required: true,
                                                value: getValues('status'),
                                            }),
                                        }}>
                                        <MenuItem disabled value={'empty'}>
                                            <span className='placeholder-text'>Select booking status...</span>
                                        </MenuItem>
                                        {statusList &&
                                            statusList?.map((status) => {
                                                return (
                                                    <MenuItem key={status.value} value={status.value}>
                                                        {status.title}
                                                    </MenuItem>
                                                );
                                            })}
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                    )}
                    <div className='row mt-4'>
                        <div className='col-1 align-self-center'>
                            <FmdGoodOutlinedIcon sx={{ fontSize: '24px' }} />
                        </div>
                        <div className='col-10'>
                            <FormControl sx={{ minWidth: 200 }}>
                                <Select
                                    labelId='room-label'
                                    id='meetingRoomId'
                                    placeholder='Select meeting room'
                                    variant='standard'
                                    onChange={() => {
                                        updateFormData();
                                    }}
                                    inputProps={{
                                        ...register('meetingRoomId', {
                                            required: true,
                                        }),
                                    }}
                                    value={watch('meetingRoomId')}
                                    sx={{ fontSize: '14px' }}>
                                    <MenuItem disabled value={'empty'}>
                                        <span className='placeholder-text'>Select meeting room...</span>
                                    </MenuItem>
                                    {rooms
                                        .filter((room) => room.status.value !== 'archived')
                                        ?.sort((a, b) => {
                                            return a.name.localeCompare(b.name, undefined, {
                                                numeric: true,
                                                sensitivity: 'base',
                                            });
                                        })
                                        .map((room) => {
                                            return (
                                                <MenuItem key={room.id} value={room.id}>
                                                    {room.name}
                                                </MenuItem>
                                            );
                                        })}
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                    <div className='row mt-4'>
                        <div className='col-1'>
                            <NotesIcon sx={{ fontSize: '24px' }} />
                        </div>
                        <div className='col-10 booking-form'>
                            <CKEditor
                                id='description'
                                editor={ClassicEditor}
                                data={watch('description')}
                                config={{
                                    toolbar: ['bold', 'italic', 'bulletedList', 'numberedList', 'blockQuote', 'link'],
                                }}
                                style={{ fontSize: '14px' }}
                                placeholder='Enter description'
                                onChange={(event: any, editor: any) => {
                                    setValue('description', editor.getData());
                                    trigger('description');
                                }}
                                onReady={(editor: any) => {}}
                                inputProps={{
                                    ...register('description', {
                                        required: false,
                                    }),
                                }}
                            />
                        </div>
                    </div>
                    {selectedBooking && (
                        <div className='row mt-4'>
                            <div className='col-1'>
                                <input
                                    type='checkbox'
                                    checked={watch('isCustomPricing') || false}
                                    onClick={() => {
                                        setValue('customPrice', bookingRate?.quotedPrice);
                                        updateFormData();
                                    }}
                                    onChange={updateFormData}
                                    {...register('isCustomPricing', {
                                        required: false,
                                    })}
                                />
                            </div>
                            <div className='col-10'>
                                <p>Custom pricing</p>
                            </div>
                        </div>
                    )}
                    {watch('isCustomPricing') === true && (
                        <div className='row mt-4'>
                            <div className='col-1 align-self-center'>
                                <PaidOutlinedIcon sx={{ fontSize: '24px' }} />
                            </div>

                            <div className='col-10 align-self-center'>
                                <FormControl sx={{ width: '75%' }}>
                                    <Input
                                        placeholder='Enter price...'
                                        value={watch('customPrice')}
                                        onChange={(e) => {
                                            setValue('customPrice', parseFloat(e.target.value));
                                            updateFormData();
                                        }}
                                        type='number'
                                        {...register('customPrice', {
                                            required: watch('isCustomPricing'),
                                        })}
                                    />
                                </FormControl>
                                {`${perRateString(watch('billingPeriod'))}`}
                            </div>
                        </div>
                    )}
                    {watch('isCustomPricing') && (
                        <div className='row mt-4'>
                            <div className='col-1 align-self-center'>
                                <CalendarTodayRoundedIcon sx={{ fontSize: '24px' }} />
                            </div>
                            <div className='col-10 align-self-center'>
                                <FormControl sx={{ width: '75%' }}>
                                    <Select
                                        labelId='room-label'
                                        id='billingPeriod'
                                        placeholder='Select billing period'
                                        value={watch('billingPeriod')}
                                        onChange={updateFormData}
                                        defaultValue={'once_off'}
                                        variant='standard'
                                        inputProps={{
                                            ...register('billingPeriod', {
                                                required: watch('isCustomPricing'),
                                            }),
                                        }}
                                        sx={{ fontSize: '14px' }}>
                                        <MenuItem disabled value={'empty'}>
                                            <span className='placeholder-text'>Select billing period...</span>
                                        </MenuItem>
                                        <MenuItem value='monthly'>Monthly</MenuItem>
                                        <MenuItem value='once_off'>Once off</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                    )}
                    <div className='row mt-4'>
                        <div className='col-1 align-self-center'></div>
                        <Col md='12' lg='6'>
                            {!watch('isCustomPricing') && (
                                <>
                                    <p className='order-subtotal'>
                                        {' '}
                                        Sub total - R
                                        {!isNaN(bookingRate?.totalPrice)
                                            ? bookingRate.totalPrice?.toFixed(2)
                                            : '0.00'}{' '}
                                    </p>
                                    {bookingRate?.discount > 0 && (
                                        <p className='order-subtotal'>
                                            {' '}
                                            Discount - R{bookingRate?.discount?.toFixed(2)}
                                        </p>
                                    )}
                                    <p className='order-total'>
                                        Total price - R
                                        {!isNaN(bookingRate?.quotedPrice)
                                            ? bookingRate?.quotedPrice?.toFixed(2)
                                            : '0.00'}{' '}
                                        (excl VAT)
                                    </p>
                                </>
                            )}
                            {watch('isCustomPricing') && (
                                <>
                                    <p className='order-subtotal'>
                                        {' '}
                                        Sub total - R
                                        {!isNaN(watch('customPrice'))
                                            ? parseFloat(watch('customPrice').toString())?.toFixed(2)
                                            : '0.00'}{' '}
                                    </p>
                                    <p className='order-total'>
                                        Total price - R
                                        {!isNaN(watch('customPrice'))
                                            ? parseFloat(watch('customPrice')?.toString())?.toFixed(2)
                                            : '0.00'}{' '}
                                        (excl VAT)
                                    </p>
                                </>
                            )}
                        </Col>
                    </div>
                </div>
                <div className={'col-md-12 col-lg-6'}>
                    <div className='row'>
                        <div className='col-11 offset-1'>
                            <h2>Guests</h2>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-1 align-self-center'>
                            <PeopleOutlineIcon sx={{ fontSize: '24px' }} />
                        </div>
                        <div className='col-10'>
                            <EmailChipListInput
                                label='Guests'
                                placeholder='Enter email address'
                                onAddItem={onAddGuest}
                                onRemoveitem={removeGuest}
                                items={guests}
                            />
                        </div>
                    </div>
                    <div className='row mt-4'>
                        <div className='col-1'>
                            <ShieldOutlinedIcon />
                        </div>
                        <div className='col-10'>
                            <Input
                                sx={{ width: '50%' }}
                                placeholder='Enter access code...'
                                {...register('accessCode', {
                                    required: false,
                                })}
                            />
                        </div>
                    </div>
                    <div className='row mt-4'>
                        <div className='col-4'>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        onChange={(e) => {
                                            setValue('sendCommunication', e.target.checked);
                                            updateFormData();
                                        }}
                                        {...register('sendCommunication', { required: false })}
                                        checked={watch('sendCommunication')}
                                    />
                                }
                                label='Include guests'
                            />
                        </div>
                        <div className='col-8'>
                            {selectedBooking?.id && (
                                <button
                                    disabled={sendEmail || !watch('accessCode')}
                                    type='submit'
                                    onClick={() => {
                                        getSendEmail(true);
                                    }}
                                    className='btn btn-sm btn-outline-primary'>
                                    Send email
                                </button>
                            )}
                            {sendEmail && (
                                <span className='ml-2'>
                                    <Spinner style={{ width: 20, height: 20 }} />
                                </span>
                            )}
                        </div>
                    </div>
                    <div className='row mt-4'>
                        <div className='col-4'>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        onChange={(e) => {
                                            setValue('includeCalendarInvites', e.target.checked);
                                            updateFormData();
                                        }}
                                        {...register('includeCalendarInvites', { required: false })}
                                        checked={watch('includeCalendarInvites')}
                                    />
                                }
                                label='Include calendar invite'
                            />
                        </div>
                    </div>
                    <div className='row mt-4'>
                        <div className='col-1'>
                            <DiscountOutlinedIcon />
                        </div>
                        <div className='col-10'>
                            <Input
                                sx={{ width: '50%' }}
                                placeholder='Enter voucher code...'
                                {...register('voucherCode', {
                                    required: false,
                                })}
                            />
                            <button
                                type='button'
                                disabled={!watch('voucherCode') || watch('voucherCode') === '' || checking}
                                className='btn btn-sm btn-outline-primary ml-4'
                                onClick={() => {
                                    const bookingDate = moment(watch('date'))
                                        ?.utc(true)
                                        .hours(getTime(watch('start'))[0])
                                        .minutes(0)
                                        .format('YYYY-MM-DD HH:mm:ss');

                                    checkIsVoucherValid({
                                        variables: {
                                            args: {
                                                booking_date: bookingDate,
                                                room_item_id: watch('meetingRoomId'),
                                                selected_account_id: clientId ? clientId : watch('client'),
                                                voucher_code: watch('voucherCode'),
                                            },
                                        },
                                    });
                                }}>
                                Apply
                            </button>
                            {checking && (
                                <span className='ml-2'>
                                    <Spinner style={{ width: 20, height: 20 }} />
                                </span>
                            )}
                            {validText !== '' && (
                                <span
                                    style={{
                                        color: voucherData && voucherData?.voucherResults?.isValid ? 'blue' : 'red',
                                        fontSize: 14,
                                        paddingLeft: 10,
                                        marginTop: 16,
                                    }}
                                    className='ml-2'>
                                    {validText}
                                </span>
                            )}
                        </div>
                    </div>
                    <div className='row mt-4'>
                        <Row className='ml-1'>
                            <BookingAddOnOption
                                addOns={
                                    rooms?.find((room) => room.id === watch('meetingRoomId'))?.checklistAddOns || []
                                }
                                roomItemId={watch('meetingRoomId')}
                                vendors={vendors}
                                rooms={rooms}
                                roomExtras={
                                    rooms.find((room) => room.id === watch('meetingRoomId'))?.meetingRoom?.roomExtras ||
                                    []
                                }
                                vendorMenuTypes={vendorMenuTypes}
                                getChecklistItems={setChecklistIdList}
                                selectedBooking={selectedBooking}
                                configurationId={configurationId}
                                getConfigurationId={getConfigurationId}
                                selectedMenuItems={selectedMenuItems}
                                getSelectedMenuItems={getSelectedMenuItems}
                                afterHourResources={afterHourResources}
                                getBookingResources={getBookingResources}
                                bookingResources={bookingResources}
                                bookingHours={bookingHours() || 0}
                                isDateClosed={isBookingInClosedDate}
                                bookingRoomAdOns={bookingRoomAdOns}
                                getBookingRoomAdOns={getBookingRoomAdOns}
                                documents={documents}
                                getDocuments={getDocuments}
                                notes={notes}
                                getNotes={getNotes}
                            />
                        </Row>
                    </div>
                </div>
            </div>
        </form>
    );
};

export default ExtendedBookingForm;
