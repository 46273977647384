/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
    Button,
    Card,
    CardFooter,
    CardHeader,
    Col,
    Container,
    Input,
    Pagination,
    PaginationItem,
    PaginationLink,
    Row,
    Table,
    UncontrolledTooltip,
} from 'reactstrap';
import BasicMenu from '../../../components/Menu';
import useToast from '../../../hooks/useToast';
import { GET_AGEING_REPORT_LIST } from '../../../graphql/financial-report.graphql';
import { useQuery } from '@apollo/client';
import { LinearProgress } from '@mui/material';
import { AgeingReport } from '../../../models/finance.report.model';
import _ from 'lodash';
import { downloadPdf } from '../../../utils/file.utils';
import { getPermission } from '../../../utils/user.utils';
import { User } from '../../../models/user.model';
import { useSelector } from 'react-redux';

export default function AgeingReportListView() {
    const [page, setPage] = useState<number>(0);
    const [rowPerPage, setRowPerPage] = useState<number>(10);
    const { showToast, Notification } = useToast();
    const [searchKey, setSearchKey] = useState<string>('');
    const navigate = useNavigate();
    const [sortDirection, setSortDirection] = useState<string>('asc');
    const [sortFiled, setSortField] = useState<string>('name');
    const { data, loading, error } = useQuery(GET_AGEING_REPORT_LIST);
    const user: User | null = useSelector((state: any) => state.user.userInfo);

    useEffect(() => {
        if (error) {
            showToast('An error has occurred while trying', 'danger');
        }
    }, [error]);

    const downloadDocument = (path: string, documentId: string) => {
        const url = `${process.env.REACT_APP_IMAGE_URL}/${path}`;
        downloadPdf(url, `${documentId}`);
    };

    const sortBy: any = (reports: AgeingReport[], field: string, direction: string) => {
        const orderedMeetingRooms = new Map([
            ['name', _.orderBy(reports, (report) => report.name, [direction === 'asc' ? 'asc' : 'desc'])],
            ['date', _.orderBy(reports, (report) => report.date, [direction === 'asc' ? 'asc' : 'desc'])],
        ]);
        return orderedMeetingRooms.get(field);
    };

    const getRowValue = (value: string) => {
        setRowPerPage(parseInt(value));
    };

    const nextPage = () => {
        setPage(page + 1);
    };
    const previousPage = () => {
        setPage(page - 1);
    };

    if (loading) {
        return <LinearProgress />;
    }

    const reports: AgeingReport[] = data?.reports;

    let filteredReports: AgeingReport[] = reports.filter((report) => {
        const isFiltered =
            report.name.toLowerCase().includes(searchKey.toLowerCase()) ||
            report.date.toLowerCase().includes(searchKey.toLowerCase());

        return isFiltered;
    });

    filteredReports = sortBy(filteredReports, sortFiled, sortDirection);

    return (
        <>
            {alert}
            {Notification}
            <Container className='mt-4' fluid>
                <Row>
                    <Col>
                        <h1>Ageing Reports</h1>
                    </Col>
                </Row>
                <Row className='mt-4'>
                    <div className='col'>
                        <Card>
                            <CardHeader className='border-0'>
                                <Row>
                                    <Col xs='6'>
                                        <h3 className='mb-0'>Ageing Reports</h3>
                                    </Col>
                                    {getPermission(user, 'finance', 'create') && (
                                        <Col className='text-right' xs='6'>
                                            <Link
                                                to={'/admin/finance/new'}
                                                className='btn btn-round btn-sm btn-dark'
                                                color='default'
                                                id='tooltip969372949'>
                                                <span className='btn-inner--text'>Import Report</span>
                                            </Link>
                                            <UncontrolledTooltip delay={0} target='tooltip969372949'>
                                                Import Report
                                            </UncontrolledTooltip>
                                        </Col>
                                    )}
                                </Row>
                                <Row className='mt-4'>
                                    <Col xs='6'>
                                        <Input
                                            className='form-control w-50'
                                            type='text'
                                            value={searchKey}
                                            placeholder='Search keyword...'
                                            onChange={(e) => {
                                                setSearchKey(e.target.value);
                                            }}
                                        />
                                    </Col>
                                    <Col xs='6'></Col>
                                </Row>
                            </CardHeader>
                            <Table className='align-items-center table-flush' responsive>
                                <thead className='thead-light'>
                                    <tr>
                                        <th
                                            onClick={() => {
                                                setSortField('name');
                                                setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                            }}
                                            className='sort'
                                            data-sort='name'
                                            scope='col'>
                                            Name
                                        </th>
                                        <th
                                            onClick={() => {
                                                setSortField('date');
                                                setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                            }}
                                            className='sort'
                                            data-sort='location'
                                            scope='col'>
                                            Date
                                        </th>
                                        <th scope='col'></th>
                                    </tr>
                                </thead>
                                <tbody className='list'>
                                    {filteredReports
                                        .slice(page * rowPerPage, page * rowPerPage + rowPerPage)
                                        .map((report) => {
                                            return (
                                                <tr
                                                    key={report.id}
                                                    style={{
                                                        cursor: getPermission(user, 'finance', 'update')
                                                            ? 'pointer'
                                                            : 'default',
                                                    }}
                                                    onClick={() => {
                                                        if (!getPermission(user, 'finance', 'update')) {
                                                            return;
                                                        }
                                                        navigate(`/admin/finance/manage/${report.id}`);
                                                    }}>
                                                    <td>{report.name}</td>
                                                    <td>{report.date}</td>
                                                    <td className='text-right'>
                                                        {getPermission(user, 'finance', 'update') && (
                                                            <>
                                                                <Button
                                                                    onClick={() => {
                                                                        if (!getPermission(user, 'finance', 'update')) {
                                                                            return;
                                                                        }

                                                                        navigate(`/admin/finance/manage/${report.id}`);
                                                                    }}
                                                                    className='btn btn-info btn-icon-only rounded-circle btn-sm'>
                                                                    <i className='fa-solid fa-pencil'></i>
                                                                </Button>
                                                                <Button
                                                                    className='btn btn-outlined-info btn-sm'
                                                                    onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        if (report.filePath) {
                                                                            downloadDocument(
                                                                                report.filePath,
                                                                                report.id,
                                                                            );
                                                                        }
                                                                    }}
                                                                    disabled={!report.filePath}>
                                                                    Document
                                                                </Button>
                                                            </>
                                                        )}
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                </tbody>
                            </Table>
                            <CardFooter className='py-4'>
                                <Row>
                                    <div className='col-4 d-flex'>
                                        <span className='min-text'>Rows per page:</span>
                                        <BasicMenu
                                            getValue={getRowValue}
                                            options={['10', '20', '100']}
                                            value={rowPerPage.toString()}
                                        />
                                    </div>

                                    <div className='col-4 d-flex'>
                                        <span className='min-text'>Page:</span>
                                        <span className='min-text pl-2'>
                                            {page + 1} of {Math.ceil(filteredReports.length / rowPerPage)}
                                        </span>
                                    </div>
                                    <div className='col-4 d-flex'>
                                        <Pagination>
                                            <PaginationItem className={page <= 0 ? 'disabled' : ''}>
                                                <PaginationLink onClick={(e) => previousPage()}>
                                                    <i className='fas fa-angle-left' />
                                                    <span className='sr-only'>Previous</span>
                                                </PaginationLink>
                                            </PaginationItem>
                                            <PaginationItem
                                                className={
                                                    page >= Math.ceil(filteredReports.length / rowPerPage) - 1
                                                        ? 'disabled'
                                                        : ''
                                                }>
                                                <PaginationLink onClick={(e) => nextPage()}>
                                                    <i className='fas fa-angle-right' />
                                                    <span className='sr-only'>Previous</span>
                                                </PaginationLink>
                                            </PaginationItem>
                                        </Pagination>
                                    </div>
                                </Row>
                            </CardFooter>
                        </Card>
                    </div>
                </Row>
            </Container>
        </>
    );
}
